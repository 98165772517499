<script setup lang="ts">
const dev = ref(true)

const appConfig = useAppConfig();
const store = useStore();
const route = useRoute();

const links = [
  {
    label: "Dashboard",
    to: "/dashboard",
    icon: "i-heroicons-home"
  },
  {
    label: "Invoices",
    to: "/invoices/all",
    icon: "i-heroicons-document-text"
  },
  {
    label: "Clients",
    to: "/clients/all",
    icon: "i-heroicons-user-group"
  },
  {
    label: "Bank accounts",
    to: "/accounts/all",
    icon: "i-heroicons-currency-dollar"
  },
]
const footer = [
  {
    label: "Logout",
    icon: "i-material-symbols-logout-rounded",
    class: "text-red-500 dark:text-red-500",
    click: () => {
      navigateTo("/auth/logout");
    },
  },
]

</script>

<template>
  <div v-if="store.validated">
    <UDashboardLayout>
      <UDashboardPanel :width="250" :resizable="{ min: 200, max: 300 }" collapsible>
        <UDashboardSidebar>
          <UDashboardSidebarLinks :links="links" />

          <UDivider class="sticky bottom-0" />

          <template #footer>
            <div class="w-full">
              <UDashboardSidebarLinks :links="footer" />

              <div class="mx-2 flex items-center">
                <!-- <Icon name="i-heroicons-user" /> -->
                <p class="ml-2 truncate font-medium text-gray-400 dark:text-gray-600">{{ store.user.email }}</p>
              </div>
            </div>
          </template>
        </UDashboardSidebar>
      </UDashboardPanel>

      <NuxtPage />

      <ErrorModal v-if="!dev" />
    </UDashboardLayout>
  </div>
  <div v-else>
    <div class="h-screen flex items-center justify-center loading-overlay">
      <div class="loading-gradient" />
      <div class="hidden">
        <NuxtPage />
      </div>
    </div>
  </div>
</template>

<style scoped>
.page-gradient {
  position: absolute;
  inset: 0;
  pointer-events: none;
  background: linear-gradient(90deg,
      rgb(var(--color-primary-500) / 0.25) 0,
      #fff 100%);
}

.dark {
  .page-gradient {
    background: linear-gradient(90deg,
        rgb(var(--color-gray-950) / 0.1) 0,
        rgb(var(--color-gray-950)) 100%);
  }
}

.loading-gradient {
  position: absolute;
  inset: 0;
  pointer-events: none;
  background: radial-gradient(50% 50% at 50% 50%,
      rgb(var(--color-primary-500) / 0.25) 0,
      #fff 100%);
}

.dark {
  .loading-gradient {
    background: radial-gradient(50% 50% at 50% 50%,
        rgb(var(--color-primary-400) / 0.1) 0,
        rgb(var(--color-gray-950)) 100%);
  }
}

.loading-overlay {
  background-size: 100px 100px;
  background-image: linear-gradient(to right,
      rgb(var(--color-gray-200)) 0.5px,
      transparent 0.5px),
    linear-gradient(to bottom,
      rgb(var(--color-gray-200)) 0.5px,
      transparent 0.5px);
}

.dark {
  .loading-overlay {
    background-image: linear-gradient(to right,
        rgb(var(--color-gray-900)) 0.5px,
        transparent 0.5px),
      linear-gradient(to bottom,
        rgb(var(--color-gray-900)) 0.5px,
        transparent 0.5px);
  }
}
</style>
