<script setup lang="ts">
const store = useStore();
const router = useRouter();
const route = useRoute();

const e = ref(null);
const show = ref(false);
watch(
  () => store.error,
  (error) => {
    if (error) {
      e.value = error;
      show.value = true;
    }
  }
);
watch(
  () => show.value,
  (value) => {
    if (!value) {
      close();
    }
  }
);

function close() {
  store.setError(null);
  router.go(-1);
  e.value = null;
}
</script>

<template>
  <UDashboardModal v-model="show" title="A aparut o eroare"
    description="Nu iti face griji, echipa noastra a fost notificata deja." icon="i-heroicons-exclamation-circle" :ui="{
      icon: { base: 'text-red-500 dark:text-red-400' } as any,
      footer: { base: 'ml-16' } as any
    }">
    <template #default>
      <div v-if="e">
        <p>
          <span class="text-gray-500">error - </span>
          <span>{{ e.error }}</span>
        </p>
        <p>
          <span class="text-gray-500">info - </span>
          <span>{{ e.info }}</span>
        </p>
        <p>
          <span class="text-gray-500">path - </span>
          <span>{{ route.fullPath }}</span>
        </p>
      </div>
      <p>
        Daca problema persista, te rugam sa salvezi un screenshot cu aceasta
        eroare si sa ne contactezi la
        <ULink to="mailto:contact@uimire.com" inactive-class="text-primary-500 dark:text-primary-500">
          contact@uimire.com
        </ULink>
      </p>
    </template>
  </UDashboardModal>
</template>
